import { Text, VStack } from '@chakra-ui/react';

export const Description = () => {
  return (
    <VStack
      fontSize={{
        base: '14px',
        lg: '16px',
      }}
      color="#3B254C"
      lineHeight="normal"
      fontWeight="400"
    >
      <Text fontWeight="600" width="100%" textAlign="left">
        Liquidity:
      </Text>
      <Text whiteSpace="pre-line" width="100%" mb="10px">
        {`All raised funds will be used to seed liquidity in CRO-BALLZ on Cronos EVM, and zkCRO-BALLZ on Cronos zkEVM. Equivalent amounts of MERY, PUUSH, & MOON will be added to the liquidity on both chains, exchange rate set at the end of the presale.`}
      </Text>
      <Text mb="10px">
        {`After $BALLZ launches on Cronos EVM, presale participants will ONLY be airdropped $BALLZ on the Cronos EVM chain.`}
      </Text>
      <Text mb="10px">
        {`Please send funds only during the offering period. Any contributions made before or after this timeframe will be forfeited and cannot be refunded. Ensure that your contribution meets the minimum amount required, as any funds below this threshold will also be forfeited. You'll receive your airdrop allocation within 48 hours after the offering concludes.`}
      </Text>
    </VStack>
  );
};
