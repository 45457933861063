import { Box } from '@chakra-ui/react';

import { Details } from './Details';
import { HeaderImg } from './HeaderImg';

export const PreSale = () => {
  return (
    <Box
      id="ballz-offering"
      mb={{
        base: '80px',
        lg: '20px',
      }}
    >
      <HeaderImg />
      <Details />
    </Box>
  );
};
