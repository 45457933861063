import { Image, Text, VStack } from '@chakra-ui/react';
import PreSaleImg from '@public/images/presale/presale-bg.png';
import { StrokeText } from '@ui/components/StrokeText';

import { BaseInfo } from './BaseInfo';

export const Details = () => {
  return (
    <VStack
      position="relative"
      height={{
        base: '1324px',
        lg: '1580px',
      }}
      maxW={{
        lg: '1207px',
        base: '100vw',
      }}
      width={{
        base: '100%',
        lg: '1207px',
      }}
      top={{
        base: '24px',
        lg: '-35px',
      }}
    >
      <Image
        src={PreSaleImg.src}
        alt="pre-sale"
        position="absolute"
        height="100%"
        objectFit={{
          base: 'cover',
          lg: 'fill',
        }}
        width="100%"
      />
      <Text
        zIndex="10"
        color="#3B254C"
        fontSize={{
          base: '14px',
          lg: '24px',
        }}
        mb={{ base: '36px', lg: '60px' }}
        pt={{
          base: '58px',
          lg: '50px',
        }}
        fontWeight="800"
        width={{
          base: '240px',
          lg: 'fit-content',
        }}
        textAlign="center"
      >
        $BALLZ will be launching on Cronos EVM and Cronos zkEVM
      </Text>
      <StrokeText
        zIndex="10"
        fontSize={{
          base: '25px',
          lg: '65px',
        }}
        lineHeight={{ base: '22px', lg: '58px' }}
        strokeWidth={{
          base: '7px',
          lg: '15px',
        }}
        strokeColor="#3B254C"
        color="#F8478B"
        width={{ base: '306px', lg: '732px' }}
        textAlign="center"
        pb={{ base: '30px', lg: '40px' }}
      >
        the presale has ended. do not send funds.
      </StrokeText>
      <BaseInfo />
    </VStack>
  );
};
