import { Image, VStack } from '@chakra-ui/react';
import BallzImg from '@public/images/presale/ballz.png';
import { StrokeText } from '@ui/components/StrokeText';

export const HeaderImg = () => {
  return (
    <VStack position="relative" spacing={0}>
      <Image
        src={BallzImg.src}
        boxSize={{
          base: '102px',
          lg: '184px',
        }}
        alt="pre-sale-ballz"
      />
      <StrokeText
        position="absolute"
        bottom="-15px"
        fontSize={{
          base: '50px',
          lg: '58px',
        }}
        strokeWidth={{
          base: '14px',
          lg: '20px',
        }}
        strokeColor="#3B254C"
        color="#FFA800"
        height={{
          base: '84px',
          lg: '52px',
        }}
        mb="2px"
        zIndex="10"
        width={{
          base: '230px',
          lg: 'fit-content',
        }}
        textAlign="center"
        lineHeight={{
          base: '44px',
          lg: 'auto',
        }}
        top={{
          base: '90px',
          lg: '145px',
        }}
      >
        $BALLZ OFFERING
      </StrokeText>
    </VStack>
  );
};
