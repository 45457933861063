import { Box, Stack, Text, VStack } from '@chakra-ui/react';
import GradientImg from '@public/images/presale/gradient.svg';

export const Tokenomics = () => {
  return (
    <VStack width="100%" color="#3B254C" fontSize="24px" fontWeight="600">
      <Stack
        width="100%"
        justify="space-between"
        align="flex-start"
        flexDirection={{
          base: 'column',
          lg: 'row',
        }}
      >
        <Text
          fontSize={{
            base: '16px',
            lg: '24px',
          }}
        >
          Tokenomics
        </Text>
        <VStack
          fontWeight="700"
          spacing={{
            base: '5px',
            lg: '10px',
          }}
          alignItems={{
            base: 'flex-start',
            lg: 'flex-end',
          }}
          fontSize={{
            base: '16px',
            lg: '24px',
          }}
        >
          <Text>30% for Presale to public</Text>
          <Text>30% for pairing with LP</Text>
          <Text
            width={{
              base: '250px',
              lg: 'fit-content',
            }}
          >
            40% for Airdrops, burns, operations and liquidity
          </Text>
        </VStack>
      </Stack>
      <Box
        mt={{
          base: '10px',
          lg: '15px',
        }}
        mb={{
          base: '20px',
          lg: '25px',
        }}
        bg={`url(${GradientImg.src})`}
        height="4px"
        width="100%"
        bgRepeat="no-repeat"
        bgPosition="center"
      />
    </VStack>
  );
};
