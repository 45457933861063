import { Box, Center, HStack, Text, VStack } from '@chakra-ui/react';
import GradientImg from '@public/images/presale/gradient.svg';
import TokenImg from '@public/images/token.svg';

import { AirdropSection } from './AirdropSection';
import { Description } from './Description';
import { Tokenomics } from './Tokenomics';

const BasicData = [
  {
    title: 'Token name',
    label: 'Ballz of Steel ($BALLZ)',
    withIcon: true,
  },
  {
    title: 'Total supply',
    label: '1,000,000,000',
  },
  {
    title: 'Price of Ballz',
    label: 'Determined by amount raised',
  },
  {
    title: 'Offering Start Date',
    label: '3 Dec, 9 am UTC',
  },
  {
    title: 'Offering Duration',
    label: '48 Hours',
  },
  {
    title: 'Min USD amount per participant',
    label: 'USD50 worth of tokens',
  },
];

const fontStyle = {
  color: '#3B254C',
  fontSize: {
    base: '16px',
    lg: '24px',
  },
  fontWeight: '600',
};
const Item = ({
  title,
  label,
  withIcon = false,
}: {
  title: string;
  label: string;
  withIcon?: boolean;
}) => {
  return (
    <VStack width="100%" spacing="15px" pb="15px">
      <HStack justify="space-between" width="100%" spacing={0}>
        <Box
          {...fontStyle}
          flex={{
            base: '2',
            lg: '3',
          }}
        >
          {title}
        </Box>
        <HStack flex="2" justify="flex-end" spacing="4px">
          <Text
            {...fontStyle}
            textAlign="right"
            whiteSpace="pre-line"
            fontWeight="700"
          >
            {label}
          </Text>
          {withIcon && (
            <Box
              bg={`url(${TokenImg.src})`}
              bgSize={{
                base: 'contain',
                lg: 'cover',
              }}
              bgPosition="center"
              bgRepeat="no-repeat"
              transform="rotate(180deg)"
              width={{
                base: '30px',
                lg: '40px',
              }}
              height={{
                base: '30px',
                lg: '40px',
              }}
            />
          )}
        </HStack>
      </HStack>
      <Box
        bg={`url(${GradientImg.src})`}
        height="4px"
        width="100%"
        bgRepeat="no-repeat"
        bgPosition="center"
      />
    </VStack>
  );
};

export const BaseInfo = () => {
  return (
    <Center
      flexDirection="column"
      width={{
        lg: '860px',
        base: '100vw',
      }}
      px={{
        base: '30px',
        lg: '10px',
      }}
      position="relative"
      top="-20px"
    >
      {BasicData.map((info) => (
        <Item {...info} key={info.title} withIcon={info.withIcon} />
      ))}

      <AirdropSection />
      <Tokenomics />
      <Description />
    </Center>
  );
};
