import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import GradientImg from '@public/images/presale/gradient.svg';

export const AirdropSection = () => {
  return (
    <VStack width="100%" color="#3B254C" fontSize="24px" fontWeight="600">
      <HStack width="100%" justify="space-between" align="flex-start">
        <Text
          fontSize={{
            base: '16px',
            lg: '24px',
          }}
        >
          You’ll receive airdrop on
        </Text>
        <Text
          fontSize={{
            base: '16px',
            lg: '24px',
          }}
          fontWeight="700"
        >
          Cronos EVM
        </Text>
      </HStack>
      <Box
        mt={{
          base: '10px',
          lg: '15px',
        }}
        mb={{
          base: '6px',
          lg: '12px',
        }}
        bg={`url(${GradientImg.src})`}
        height="4px"
        width="100%"
        bgRepeat="no-repeat"
        bgPosition="center"
      />
      <HStack width="full" justify="flex-end" mb={{ base: '24px', lg: '54px' }}>
        <Text
          fontSize={{
            base: '10px',
            lg: '16px',
          }}
          fontWeight="400"
          textAlign="right"
          width={{ base: '180px', lg: '100%' }}
        >
          $Ballz will also be launched in Cronos zkEVM as well after airdrop
        </Text>
      </HStack>
    </VStack>
  );
};
